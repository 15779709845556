import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { ESanityField } from '@core/type/sanity';

import { PortableText } from '../portable-text';
import { EPortableTextType } from '../portable-text/interface-portable-text';
import { AccordionProductDetails } from './AccordionProductDetails';
import { ProductSpecifications } from './ProductSpecifications';
import { TabWidgetPhoneProps } from './interface-tab-product';

export const TabWidgetPhone = ({ tab }: TabWidgetPhoneProps) => {
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  return tab._type === (ESanityField.PRODUCT_SPECIFICITIES as string) ? (
    <AccordionProductDetails
      title={tab.title ?? tWidget('tabProduct.titleSpecifications')}
      key={tab._key}
      content={<ProductSpecifications />}
    />
  ) : (
    <AccordionProductDetails
      key={tab._key}
      title={tab.title}
      content={<PortableText value={tab.content} type={EPortableTextType.PRODUCT_SHOW} />}
    />
  );
};
