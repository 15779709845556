import useTranslation from 'next-translate/useTranslation';
import { useContext } from 'react';

import { Grid } from '@core/component';
import { Translate } from '@core/constant';
import { productContext, useContextMediaQuery } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { EQueryKey, ProductPageEntity } from '@core/type/api';
import { SanityPageResponse } from '@core/type/sanity';
import { formatUnitShort } from '@core/util';

import { LineSpecification } from './LineSpecification';
import { ProductSpecificitiesProps } from './interface-tab-product';

export const ProductSpecifications = () => {
  const { isPhone } = useContextMediaQuery();
  const product = useContext(productContext);

  const { data } = useQueryApi<ProductPageEntity, SanityPageResponse>(
    EQueryKey.PRODUCT_SHOW,
    {
      id: product.id,
    } as SanityPageResponse & { id: number },
    { enabled: !!product?.id },
  );

  return isPhone ? (
    <>
      <FirstContentPart specificities={data?.specificities} />
      <SecondContentPart specificities={data?.specificities} />
    </>
  ) : (
    <Grid isContainer columnSpacing={114}>
      <Grid md={6}>
        <FirstContentPart specificities={data?.specificities} />
      </Grid>
      <Grid md={6}>
        <SecondContentPart specificities={data?.specificities} />
      </Grid>
    </Grid>
  );
};

const FirstContentPart = ({ specificities }: ProductSpecificitiesProps) => {
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  return (
    <>
      <LineSpecification
        title={tWidget('tabProduct.specifications.productId')}
        value={specificities?.sku}
      />
      <LineSpecification
        title={tWidget('tabProduct.specifications.year')}
        value={specificities?.issueYear}
      />
      <LineSpecification
        title={tWidget('tabProduct.specifications.mintMark')}
        value={specificities?.mintMark}
      />
      <LineSpecification
        title={tWidget('tabProduct.specifications.thickness')}
        value={specificities?.thickness}
      />
      <LineSpecification
        title={tWidget('tabProduct.specifications.purity')}
        value={specificities?.purity}
      />
    </>
  );
};

const SecondContentPart = ({ specificities }: ProductSpecificitiesProps) => {
  const { language } = useLanguage();
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  const diameterProps =
    specificities?.diameter?.number &&
    formatUnitShort(language, specificities?.diameter.unit, specificities?.diameter.number);

  const lengthProps =
    specificities?.length?.number &&
    formatUnitShort(language, specificities?.length.unit, specificities?.length.number);

  const widthProps =
    specificities?.width?.number &&
    formatUnitShort(language, specificities?.width.unit, specificities?.width.number);

  const weightProps = specificities?.weight?.filter(({ number }) => number) || [];

  return (
    <>
      {weightProps.map(({ number, unit }) => (
        <LineSpecification
          key={unit}
          title={tWidget(`tabProduct.specifications.${unit}`)}
          value={formatUnitShort(language, unit, number)}
        />
      ))}
      <LineSpecification
        title={tWidget('tabProduct.specifications.diameter')}
        value={diameterProps}
      />
      <LineSpecification title={tWidget('tabProduct.specifications.length')} value={lengthProps} />
      <LineSpecification title={tWidget('tabProduct.specifications.width')} value={widthProps} />
    </>
  );
};
