import useTranslation from 'next-translate/useTranslation';
import styled, { css } from 'styled-components';

import { Box, Icon, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useLanguage, useQueryApi } from '@core/hook';
import { EColor, EIcon, ESize, ETypographyVariant } from '@core/type';
import { EQueryKey, PageRatingRequest, PageRatingResponse } from '@core/type/api';
import { formatNumber } from '@core/util';

import {
  RatingStarProps,
  StyledStarWrapperProps,
  WidgetPageRatingProps,
} from './interface-rating-stars';

const DEFAULT_RATE = 4;

export const WidgetPageRating = ({ pageId }: WidgetPageRatingProps) => {
  const { language } = useLanguage();
  const { t } = useTranslation(Translate.common.WIDGET);

  const { data } = useQueryApi<PageRatingResponse, PageRatingRequest>(
    EQueryKey.WIDGET_PAGE_RATING,
    {
      pageId,
    },
  );

  const rating = data?.rating ? Math.max(data?.rating, DEFAULT_RATE) : DEFAULT_RATE;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const is4AndHalfStars = rating > 4.1 && rating < 4.7;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const is5Stars = rating >= 4.7;

  return (
    <Box margin={'0px auto'} maxWidth={'fit-content'} padding={'28px'} textAlign={'center'}>
      <Typography variant={ETypographyVariant.BODY3} marginBottom={'8px'}>
        {t('pageRating.defaultTitle')}
      </Typography>
      <Box display={'flex'} gap={'6px'} justifyContent={'center'}>
        <RatingStar isFilled />
        <RatingStar isFilled />
        <RatingStar isFilled />
        <RatingStar isFilled />
        <RatingStar isFilled={is4AndHalfStars || is5Stars} isHalfRating={is4AndHalfStars} />
      </Box>
      <Typography variant={ETypographyVariant.CAPTION1} marginTop={'8px'}>
        {formatNumber(language, rating)}/5
      </Typography>
    </Box>
  );
};

const RatingStar = ({ isFilled, isHalfRating }: RatingStarProps) => (
  <StyledStarWrapper isHalfRating={isHalfRating}>
    <Icon
      type={EIcon.STAR}
      size={ESize.SM}
      secondColor={isFilled && { commonType: EColor.YELLOW, intensity: EColor.R100 }}
    />
  </StyledStarWrapper>
);

const StyledStarWrapper = styled(Box)(
  ({ isHalfRating }: StyledStarWrapperProps) => css`
    height: 16px;
    width: 16px;

    ${isHalfRating
      ? css`
          svg path:first-of-type {
            scale: 0.7;
            transform: translate(14%, 20%);
          }
        `
      : ''}
  `,
);
