import { useEffect, useRef, useState } from 'react';

import { CONTAINER_GUTTERS, MIN_SCREEN_WIDTH, themeContainerMaxWidth } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { ProductEntity } from '@core/type/api';

export const GAP_BETWEEN_CARDS = 16;
const VISIBLE_CARDS_ON_DESKTOP = 3;

export const useCarouselProducts = (products: ProductEntity[]) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const sliderWidthRef = useRef<number>(0);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const { isPhone, mediaQuery } = useContextMediaQuery();

  const setSlideWidth = () => {
    sliderWidthRef.current = isPhone
      ? Math.max(window.screen.width, MIN_SCREEN_WIDTH) - CONTAINER_GUTTERS
      : (themeContainerMaxWidth[mediaQuery] as number) - CONTAINER_GUTTERS;
  };

  const handleScroll = (left: number) => {
    carouselRef?.current?.scrollTo({ left, behavior: 'smooth' });
  };

  const resetScrollLeft = () => {
    handleScroll(0);
    setScrollLeft(0);
  };

  const handleResize = () => {
    setSlideWidth();
    resetScrollLeft();
  };

  const onNext = () => {
    handleScroll(
      scrollLeft +
        cardRef?.current?.offsetWidth * VISIBLE_CARDS_ON_DESKTOP +
        GAP_BETWEEN_CARDS * VISIBLE_CARDS_ON_DESKTOP,
    );
  };

  const onPrevious = () => {
    handleScroll(
      scrollLeft -
        (cardRef?.current?.offsetWidth * VISIBLE_CARDS_ON_DESKTOP +
          GAP_BETWEEN_CARDS * VISIBLE_CARDS_ON_DESKTOP),
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollLeft(Math.ceil(carouselRef?.current?.scrollLeft) || 0);
      setScrollWidth(
        Math.ceil(carouselRef?.current?.scrollWidth - carouselRef?.current?.offsetWidth) || 0,
      );
    };

    if (carouselRef?.current?.offsetWidth !== undefined) {
      handleScroll();
    }

    carouselRef?.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      carouselRef?.current?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products.length]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ref: carouselRef,
    cardRef,
    onNext,
    onPrevious,
    scrollLeft,
    scrollWidth,
    sliderWidth: sliderWidthRef.current,
    resetScrollLeft,
  };
};
