import { ArbitraryTypedObject, PortableTextBlock } from '@portabletext/types/src';
import { ReactNode } from 'react';

import { SanityWidget } from '@core/type/sanity';

export type PortableTextComponentEntity = SanityWidget & {
  portableText: PortableTextBlock | ArbitraryTypedObject;
};

export type PortableTextChildProps = {
  props: {
    children: ReactNode;
  };
};

export enum EPortableTextType {
  DEFAULT = 'default',
  PRODUCT_SHOW = 'productShow',
}
