import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import { Box, Button, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, ESize, ETypographyVariant } from '@core/type';
import { formatCurrency } from '@core/util';

import { PriceModalProps } from '../interface-charts';

export const ModalPrice = ({
  isOpen,
  onCloseModal,
  categoryTitleDowncase,
  ask,
  bid,
}: PriceModalProps): JSX.Element => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { t } = useTranslation(Translate.common.WIDGET);

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} size={ESize.LG}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H4} textAlign="center">
          {t('chart.show.priceDetailsModal.header', { categoryTitleDowncase })}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Box marginBottom="8px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.buyback')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            {formatCurrency(language, currency, ask)}
          </Typography>
        </Box>
        <Box marginBottom="32px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.purchase')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            {formatCurrency(language, currency, bid)}
          </Typography>
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.buyPrice')}: `}
          </Typography>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.buyPriceHtml`}
            values={{
              categoryTitleDowncase,
            }}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
            }}
          />
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.sellPrice')}: `}
          </Typography>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.sellPriceHtml`}
            values={{
              categoryTitleDowncase,
            }}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
            }}
          />
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.spread')}: `}
          </Typography>
          <Trans i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.spreadText`} />
        </Box>
      </Modal.Body>

      <Modal.Footer>
        <Box display="flex" justifyContent="center">
          <Button onClick={onCloseModal} padding="0 16px">
            {t('chart.btnOkThanks')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
