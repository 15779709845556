import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';

import { Box, SkeletonTypography, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { RecentlyViewedProductsStorage } from '@core/storage';
import { EActionAttribute, ETypographyVariant } from '@core/type';
import { EQueryKey, ProductShopEntities } from '@core/type/api';

import { CarouselProducts, sliderHeight } from '../carousel-products';

export const WidgetRecentlyViewedProducts = () => {
  const { isPhone } = useContextMediaQuery();
  const ids = useMemo(() => RecentlyViewedProductsStorage.get(), []);
  const isEnabled = !!ids?.length;
  const { data, isLoading, isError } = useQueryApi<ProductShopEntities, { ids?: number[] }>(
    EQueryKey.WIDGET_RECENTLY_VIEWED_PRODUCTS,
    { ids },
    { enabled: isEnabled },
  );

  const products = useMemo(() => {
    if (!data) return [];
    const objMap = new Map(data.products.map((p) => [p.id, p]));
    return ids.reduce((acc: ProductShopEntities['products'], id) => {
      const product = objMap.get(id);
      if (product) acc.push(product);
      return acc;
    }, []);
  }, [data, ids]);

  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  if (isLoading && isEnabled) {
    return (
      <Box height={sliderHeight(isPhone)} marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  if (isError || !products.length) {
    return null;
  }

  return (
    <Box>
      <Typography as={EActionAttribute.H2} variant={ETypographyVariant.H1} marginBottom={'24px'}>
        {tWidget('recentlyViewedProducts.title')}
      </Typography>
      <CarouselProducts products={products} />
    </Box>
  );
};
