import {
  Box,
  LinkTranslatedStyledAsLink,
  SelectOption,
  SelectText,
  Typography,
} from '@core/component';
import { SelectBaseOptionProps, SelectTextProps } from '@core/component/interface';
import { EActionAttribute, EColor, ERouting, ESize } from '@core/type';
import { ChartChoices, buildQueryFromUserChartChoices } from '@core/util';

import { SelectChartCategoriesProps } from './interface-charts';

const SelectChartOption = (props: SelectBaseOptionProps) => (
  <SelectOption padding={'0'} {...props} />
);

export const SelectChartCategories = ({
  options,
  type,
  linkProps,
  ...restProps
}: SelectTextProps & SelectChartCategoriesProps) => (
  <SelectText
    Option={SelectChartOption}
    options={options.map(({ label, value, content }) => {
      return {
        value,
        content: (
          <Box display={'flex'} alignItems={'center'} paddingRight={'20px'}>
            <Typography lineHeight={'20px'} transition={'0.3s'} as={EActionAttribute.SPAN}>
              {content}
            </Typography>
          </Box>
        ),
        label: linkProps ? (
          <LinkTranslatedStyledAsLink
            keyRoute={ERouting.CHARTS}
            disableScroll
            query={buildQueryFromUserChartChoices({
              [type]: value,
              ...linkProps,
            } as ChartChoices)}
            display="flex"
            isUnderlined={false}
            isUnderlinedHover={false}
          >
            <Box padding={'10px 16px'}>{label}</Box>
          </LinkTranslatedStyledAsLink>
        ) : (
          <Box padding={'10px 16px'}>
            <Typography>{label}</Typography>
          </Box>
        ),
      };
    })}
    size={ESize.SM}
    borderColor={{ semanticType: EColor.NEUTRAL, variant: EColor.MAIN }}
    {...restProps}
  />
);
