import { useEffect, useRef, useState } from 'react';

import { SanitySubcategoryLinkObjProps } from '@core/type/sanity';

const SLIDES_GAP = 8;

export const useCarouselSubcategories = (
  collections: SanitySubcategoryLinkObjProps[],
  slidesGap = SLIDES_GAP,
) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const slidesWidthRef = useRef<number[]>([]);

  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const handleScroll = (left: number) => {
    carouselRef?.current?.scrollTo({ left, behavior: 'smooth' });
  };

  const setSlidesWidth = (index: number, width: number) => {
    slidesWidthRef.current[index] = width;
  };

  const handleNext = () => {
    const positionToScroll = slidesWidthRef.current.reduce((acc, slideWidth) => {
      if (acc <= scrollLeft + 1) {
        return acc + slideWidth;
      }
      return acc;
    }, 0);

    handleScroll(positionToScroll);
  };

  const handlePrevious = () => {
    const positionToScroll = slidesWidthRef.current.reduce((acc, slideWidth) => {
      if (acc + slideWidth <= scrollLeft - 1) {
        return acc + slideWidth;
      }
      return acc;
    }, 0);

    handleScroll(positionToScroll);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollLeft(Math.ceil(carouselRef?.current?.scrollLeft) || 0);
      setScrollWidth(
        Math.ceil(
          carouselRef?.current?.scrollWidth - carouselRef?.current?.offsetWidth - slidesGap,
        ) || 0,
      );
    };

    if (carouselRef?.current?.offsetWidth !== undefined) {
      handleScroll();
    }

    carouselRef?.current?.addEventListener('scroll', handleScroll);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      carouselRef?.current?.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections?.length]);

  return {
    ref: carouselRef,
    scrollLeft,
    scrollWidth,
    collections,
    setSlidesWidth,
    handleNext,
    handlePrevious,
  };
};
