import useTranslation from 'next-translate/useTranslation';
import { memo, useContext } from 'react';

import { Box, Grid, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { productContext, useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EActionAttribute, ETypographyVariant } from '@core/type';
import { EQueryKey, ProductPageEntity } from '@core/type/api';

import { ProductCardLink } from '../card';

const DESKTOP_MAX_CARD_LENGTH = 3;
const MOBILE_MAX_CARD_LENGTH = 4;

const SectionSuggestedProductsContainer = () => {
  const { isDesktop, isMobile } = useContextMediaQuery();
  const product = useContext(productContext);
  const { t } = useTranslation(Translate.common.WIDGET);

  const { data } = useQueryApi<ProductPageEntity, { id: number }>(
    EQueryKey.PRODUCT,
    { id: product.id },
    { enabled: !!product?.id },
  );

  const products = data?.suggestedProducts?.slice(
    0,
    isDesktop ? DESKTOP_MAX_CARD_LENGTH : MOBILE_MAX_CARD_LENGTH,
  );

  return (
    <Box as={EActionAttribute.SECTION}>
      <Typography
        textAlign="center"
        marginBottom={'24px'}
        as={EActionAttribute.H4}
        variant={ETypographyVariant.H5}
      >
        {t('productSuggestion.productsSuggestionTitle')}
      </Typography>
      <Grid
        isContainer
        rowSpacing={30}
        columnSpacing={
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          isMobile ? 6 : 30
        }
      >
        {products?.map((product) => (
          <Grid key={product.id} xs={6} lg={4}>
            <ProductCardLink titleTag={EActionAttribute.H5} product={product} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const SuggestedProducts = memo(SectionSuggestedProductsContainer);
