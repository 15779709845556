import { useState } from 'react';

import { Box } from '@core/component';
import { useContextMediaQuery, useContextProfile, useContextUser } from '@core/context';
import { EColor } from '@core/type';

import { BannerSpotlight } from './BannerSpotlight';

export const SpotlightBanner = ({ fn }: { fn: (bool: boolean) => void }) => {
  const { isPhone } = useContextMediaQuery();
  const { hasNewsletterSubscription } = useContextProfile();
  const { isLogged } = useContextUser();

  if (isLogged && hasNewsletterSubscription) {
    return null;
  }

  return (
    <Box
      margin={isPhone ? '0 -20px -24px' : '0 0 68px'}
      paddingBottom={isPhone ? '56px' : '0'}
      backgroundColor={isPhone ? EColor.INFO : undefined}
    >
      <BannerSpotlight onSuccessfulAnimationEnd={() => fn(true)} />
    </Box>
  );
};

export const SpotlightBannerWidgetWrapper = () => {
  const [isHidden, setIsHidden] = useState(false);

  if (isHidden) {
    return null;
  }

  return <SpotlightBanner fn={setIsHidden} />;
};
