import { useEventMixpanel } from '@mixpanel';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { Box } from '@core/component';
import { useContextMediaQuery } from '@core/context';
import { EPosition } from '@core/type';
import { ProductEntity } from '@core/type/api';

import { ProductCardLink } from '../../card/ProductCardLink';
import { ButtonArrow } from './ButtonArrow';
import { GAP_BETWEEN_CARDS, useCarouselProducts } from './use-carousel-products';

const SCROLL_COMPENSATION_WIDTH = 20;

export const CarouselProducts = ({
  products,
  currentStep,
}: {
  products: ProductEntity[];
  currentStep?: number;
}) => {
  const { isPhone, isMobile } = useContextMediaQuery();
  const {
    ref: carouselRef,
    cardRef,
    scrollLeft,
    scrollWidth,
    onNext: onProductsNext,
    onPrevious: onProductsPrevious,
    sliderWidth,
    resetScrollLeft,
  } = useCarouselProducts(products);
  const { trackCarouselProductsEvent } = useEventMixpanel();

  useEffect(() => {
    // reset carousel when group changes
    resetScrollLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const slideWidth = isPhone
    ? 'calc(100vw / 1.9 - 20px)'
    : isMobile
      ? 'calc(100vw / 2.6 - 20px)'
      : '284px';

  return (
    <Box position={'relative'}>
      <StyledCarouselWrapper
        height={sliderHeight(isPhone)}
        ref={carouselRef}
        maxWidth={sliderWidth}
      >
        {products.map((product) => (
          <Box key={product.id} width={slideWidth} flexShrink={'0'} ref={cardRef}>
            <ProductCardLink
              product={product}
              onClick={() => {
                trackCarouselProductsEvent(product, product?.slug);
              }}
            />
          </Box>
        ))}
      </StyledCarouselWrapper>

      {!isMobile && scrollWidth > sliderWidth && (
        <>
          <ButtonArrow
            orientation={EPosition.LEFT}
            onClick={onProductsPrevious}
            isActive={scrollLeft - SCROLL_COMPENSATION_WIDTH > 0}
          />
          <ButtonArrow
            orientation={EPosition.RIGHT}
            onClick={onProductsNext}
            isActive={scrollLeft + SCROLL_COMPENSATION_WIDTH < scrollWidth}
          />
        </>
      )}
    </Box>
  );
};

export const sliderHeight = (isPhone: boolean) => (isPhone ? '358px' : '450px');

const StyledCarouselWrapper = styled(Box)(
  () => css`
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    padding-bottom: 24px;
    gap: ${GAP_BETWEEN_CARDS}px;
    margin-bottom: -30px;
    scrollbar-color: transparent transparent !important;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);
