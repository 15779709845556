import { Logger } from '@core/logger';
import { EStorageType } from '@core/type';

import { EStorageKey } from '../constants';
import { getItem, logStorage, setItem } from '../helpers';

const MAX_RECENTLY_VIEWED = 15;

const addRecentlyViewedProduct = (productId: number) => {
  if (!productId) return;

  logStorage('addRecentlyViewedProduct');

  const ids = getRecentlyViewedProducts();
  const newIds = [productId, ...ids.filter((id) => id !== productId)].slice(0, MAX_RECENTLY_VIEWED);

  setItem(EStorageType.LOCAL_STORAGE, EStorageKey.RECENTLY_VIEWED_PRODUCTS, JSON.stringify(newIds));
};

const getRecentlyViewedProducts = (): number[] => {
  try {
    const ids = getItem(EStorageType.LOCAL_STORAGE, EStorageKey.RECENTLY_VIEWED_PRODUCTS);
    return (JSON.parse(ids) as number[]) || [];
  } catch (error) {
    Logger.logError(['Error parsing recently viewed products:', error]);
    return [];
  }
};

export class RecentlyViewedProductsStorage {
  public static readonly add = addRecentlyViewedProduct;
  public static readonly get = getRecentlyViewedProducts;
}
