import { ReactNode } from 'react';

import { ECurrency, ELiveChartsPeriod, MetalPrice, Price, WeightUnitType } from '@core/type';
import { ChartChoices } from '@core/util';

export type ChipPeriod = {
  period: ELiveChartsPeriod;
  text: string;
};

export type LivePriceProps = { deskbid: number; metalPrice: Price; currency: ECurrency };

export type PriceModalProps = MetalPrice & {
  isOpen: boolean;
  onCloseModal: () => void;
  categoryTitleDowncase: string;
};

export enum ESelectChartCategory {
  METAL = 'metal',
  WEIGHT_UNIT = 'weightUnit',
  CURRENCY = 'currency',
}

export type WidgetChartInvestmentProps = {
  metalValue: string;
  hasMonthlyInvestment: boolean;
  setMetalValue: (value: string) => void;
  setMonthlyInvestmentValue: (value: number) => void;
  setInitialInvestmentValue: (value: number) => void;
  setHasMonthlyInvestment: (value: boolean) => void;
};

export type ChipProps = {
  children: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
};

export type PriceChangeProps = {
  priceChange: number;
  isPositive: boolean;
  percentage: {
    number: number;
  };
};

export type SelectChartCategoriesProps = {
  type: ESelectChartCategory;
  linkProps?: Partial<ChartChoices>;
};

export type WidgetChartProps = {
  currency?: ECurrency;
  shouldChangeUrl?: boolean;
  weightUnit?: WeightUnitType;
  title?: string;
  subtitle?: string;
};
