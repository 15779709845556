import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import { CategoriesService } from '@core/api';
import {
  Box,
  Image,
  InputTextBase,
  InputWrapper,
  LinkTranslatedStyledAsButton,
  SelectCurrency,
  SelectText,
  Typography,
} from '@core/component';
import {
  DEFAULT_AMOUNT_VALUE,
  Translate,
  currencyIsoToCurrencySlug,
  metalToSlug,
} from '@core/constant';
import { useContextCurrency, useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import {
  EActionAttribute,
  EColor,
  ECurrency,
  EFontWeight,
  EMetal,
  ERouting,
  ESize,
  ETypographyVariant,
  SelectChangeEvent,
} from '@core/type';
import { formatNumber } from '@core/util';

const DECIMAL_PLACES = 2;

const OptionText = ({ text }: { text: string }) => <Typography>{text}</Typography>;

export const InvestmentAssistantBlock = () => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { isDesktop, isPhone, isTablet, mediaQuery } = useContextMediaQuery();

  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  const [selectMetal, setSelectMetal] = useState<EMetal>(EMetal.GOLD);
  const [selectValue, setSelectValue] = useState<number>(undefined);
  const [selectCurrency, setSelectCurrency] = useState<ECurrency>(currency);
  const [valueToShow, setValueToShow] = useState<string>(undefined);

  const isLargeDesktop: boolean = mediaQuery === ESize.XL;

  const changeCurrency = ({ target: { value } }: SelectChangeEvent) => {
    setSelectCurrency(value as ECurrency);
  };

  const changeValue = ({ target: { value } }: SelectChangeEvent) => {
    const number = parseInt(value);

    if (typeof number === 'number' && !isNaN(number)) {
      setSelectValue(parseInt(value));
      setValueToShow(number.toString() || '');
    } else {
      setSelectValue(undefined);
      setValueToShow('');
    }
  };

  useEffect(() => {
    setSelectCurrency(currency);
  }, [currency]);

  return (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={isDesktop ? 'row' : 'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={isPhone ? '54px' : isTablet ? '64px' : '0'}
    >
      <Box maxWidth={isLargeDesktop ? '750px' : isDesktop ? '618px' : '100%'}>
        <Typography as={EActionAttribute.H2} variant={ETypographyVariant.H1} marginBottom={'24px'}>
          {tWidget('home.tryOurSavings')}
        </Typography>

        <Typography variant={ETypographyVariant.BODY1} marginBottom={isPhone ? '64px' : '40px'}>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:home.assistantDesc`}
            components={{
              strong: (
                <Typography
                  as={EActionAttribute.SPAN}
                  variant={ETypographyVariant.BODY1}
                  fontWeight={EFontWeight.BOLD}
                />
              ),
            }}
          />
        </Typography>

        <Box
          display={'flex'}
          flexDirection={isLargeDesktop ? 'row' : 'column'}
          alignItems={isLargeDesktop ? 'flex-end' : isDesktop ? 'flex-start' : 'center'}
          gap={isLargeDesktop ? '24px' : '32px'}
        >
          <Box
            width={isLargeDesktop ? undefined : '100%'}
            maxWidth={isLargeDesktop ? 'none' : '550px'}
          >
            <Typography
              as={EActionAttribute.LABEL}
              variant={ETypographyVariant.BODY3}
              fontWeight={EFontWeight.BOLD}
            >
              {tWidget('home.chooseMetal')}
            </Typography>

            <Box marginTop={'8px'}>
              {CategoriesService.getCategories(language).categories?.length > 0 && (
                <SelectText
                  value={selectMetal}
                  size={ESize.LG}
                  backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
                  options={CategoriesService.getCategories(language).categories?.map(
                    ({ title, metalIso }) => ({
                      label: <OptionText text={title} />,
                      value: metalIso,
                      content: <OptionText text={title} />,
                    }),
                  )}
                  width={isLargeDesktop ? '162px' : '100%'}
                  onChange={({ target: { value } }: SelectChangeEvent<string>) => {
                    setSelectMetal(value as EMetal);
                  }}
                />
              )}
            </Box>
          </Box>

          <Box width={'100%'} maxWidth={isLargeDesktop ? 'none' : '550px'}>
            <Typography
              as={EActionAttribute.LABEL}
              variant={ETypographyVariant.BODY3}
              fontWeight={EFontWeight.BOLD}
            >
              {tWidget('home.enterBudget')}
            </Typography>

            <Box marginTop={'8px'}>
              <InputWrapper
                display={'flex'}
                position={'relative'}
                padding={'16px 0 16px 16px'}
                backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
              >
                <Box
                  position={'absolute'}
                  left={'0'}
                  padding={'16px 16px 16px 128px'}
                  top={'0'}
                  height={'56px'}
                  width={'100%'}
                  textOverflow={'ellipsis'}
                >
                  <Box
                    position={'absolute'}
                    left={'0'}
                    padding={'16px 16px 16px 128px'}
                    top={'0'}
                    height={'56px'}
                    width={'100%'}
                    textOverflow={'ellipsis'}
                  >
                    <InputTextBase
                      placeholder={formatNumber(language, DEFAULT_AMOUNT_VALUE, {
                        minimumFractionDigits: DECIMAL_PLACES,
                      })}
                      value={valueToShow}
                      onChange={changeValue}
                    />
                  </Box>
                </Box>
                <Box position={'relative'}>
                  <SelectCurrency
                    iconColor={EColor.PRIMARY}
                    color={EColor.PRIMARY}
                    fn={changeCurrency}
                    value={selectCurrency}
                    width={'96px'}
                    marginRight={'20px'}
                  />
                </Box>
              </InputWrapper>
            </Box>
          </Box>

          <Box display={'flex'}>
            <LinkTranslatedStyledAsButton
              keyRoute={ERouting.SAVING_ASSISTANT}
              size={ESize.LG}
              padding={isDesktop ? '0 20px' : '0 16px'}
              query={{
                amount: String(selectValue || DEFAULT_AMOUNT_VALUE),
                currency: currencyIsoToCurrencySlug[currency],
                category: metalToSlug[language][selectMetal],
              }}
            >
              {tWidget('home.showResults')}
            </LinkTranslatedStyledAsButton>
          </Box>
        </Box>
      </Box>

      <Box position={'relative'} width={'306px'} height={'486px'} marginLeft={'16px'}>
        <Image
          src={getStaticPathImage('homepage/investment-assistant.png')}
          blurDataURL={getStaticPathImageBlurData(
            EFilenameBlur['homepage/investment-assistant.png'],
          )}
          alt={tWidget('home.goldImgAlt')}
          width={306}
          height={486}
        />
      </Box>
    </Box>
  );
};
