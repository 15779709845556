import useTranslation from 'next-translate/useTranslation';

import { LinkTranslatedStyledAsButton } from '@core/component';
import { Translate } from '@core/constant';
import { useContextRedirectAfterSignInRoute, useContextUser } from '@core/context';
import { EActionAttribute, EIcon, ERouting } from '@core/type';
import { ChartChoices } from '@core/util';

export const ButtonSetMarketAlert = ({ metal }: Pick<ChartChoices, 'metal'>) => {
  const { isLogged } = useContextUser();
  const { setRoute, setQuery } = useContextRedirectAfterSignInRoute();
  const { t } = useTranslation(Translate.common.WIDGET);

  return (
    <LinkTranslatedStyledAsButton
      keyRoute={ERouting.ALERT_SETTINGS}
      query={{ metalIso: metal }}
      as={EActionAttribute.LINK}
      startIcon={EIcon.ALERT}
      isFullWidth
      onClick={() => {
        if (!isLogged) {
          setRoute(ERouting.ALERT_SETTINGS);
          setQuery({ metalIso: metal });
        }
      }}
    >
      {t('chart.setMarketAlertBtn')}
    </LinkTranslatedStyledAsButton>
  );
};
