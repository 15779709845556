import { FC } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from '@core/component';
import { TypographyProps } from '@core/component/interface';
import { WithThemeProps } from '@core/type';

export const Heading = ({ children, ...restProps }: TypographyProps) => (
  <StyledTypography marginTop={12} marginBottom={24} {...restProps}>
    {children}
  </StyledTypography>
);

const StyledTypography: FC<TypographyProps> = styled(Typography)<TypographyProps>((
  props: TypographyProps & WithThemeProps,
) => {
  const {
    theme: {
      typography: { defaultVariant, fontWeight: fontWeightTheme, ...restTypography },
    },
    variant = defaultVariant,
    fontWeight: fontWeightVariant,
  } = props;

  return css`
    span {
      font-size: ${restTypography[variant].fontSize};
      font-weight: ${fontWeightTheme[fontWeightVariant || restTypography[variant].fontWeight]};
      line-height: ${restTypography[variant].lineHeight};
    }
  `;
});
