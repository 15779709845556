import { getEnvironmentPublic } from '@web-app/helpers';

import {
  ProviderLiveChartsPrice,
  ProviderMetalIso,
  ProviderMetalPrice,
  ProviderWeightUnit,
} from '@core/provider';
import { EMetal, ESign, LiveChartsPriceEntity } from '@core/type';

import { CHART_UNIT_DEFAULT } from './constants';
import { ViewChartsWrapperProps } from './interface-charts';

export const ViewChartsWrapper = ({
  metal,
  children,
  weightUnit = CHART_UNIT_DEFAULT,
}: ViewChartsWrapperProps) => {
  const { wsHost } = getEnvironmentPublic();
  const metalPriceInitialize = {
    [EMetal.GOLD]: { isPositive: true, name: 'price.gold' },
    [EMetal.SILVER]: { isPositive: true, name: 'price.silver' },
    [EMetal.PLATINUM]: { isPositive: true, name: 'price.platinum' },
    [EMetal.PALLADIUM]: { isPositive: true, name: 'price.palladium' },
  };

  const initialPricesList: LiveChartsPriceEntity[] = [];

  const liveChartsInitialize = {
    pricesList: initialPricesList,
    priceChanges: {
      metalPrice: 0,
      metalPriceOunce: 0,
      difference: 0,
      changeSign: ESign.POSITIVE,
      percentage: {
        number: 0,
      },
    },
    spotPrice: {
      deskbid: 0,
      deskask: 0,
      deskaskOunce: 0,
      changePrice: 0,
      changeSign: ESign.POSITIVE,
    },
  };

  return (
    <ProviderMetalPrice metalPriceInitialize={metalPriceInitialize} wsHost={wsHost}>
      <ProviderLiveChartsPrice liveChartsInitialize={liveChartsInitialize} wsHost={wsHost}>
        <ProviderMetalIso providerMetalIsoInitialize={metal}>
          <ProviderWeightUnit providerWeightUnitInitialize={weightUnit}>
            {children}
          </ProviderWeightUnit>
        </ProviderMetalIso>
      </ProviderLiveChartsPrice>
    </ProviderMetalPrice>
  );
};
