import useTranslation from 'next-translate/useTranslation';
import NextLink from 'next/link';
import { memo } from 'react';

import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useFullUrl } from '@core/hook';
import { EActionAttribute, ECurrency, SelectChangeEvent } from '@core/type';
import { DEFAULT_CURRENCY, getCurrencyLinkHref, getIsoCurrency } from '@core/util';

import { IconCurrency } from '../icon/IconCurrency';
import { Box, BoxProps } from '../layout';
import { Link } from '../link';
import { Typography } from '../typography';
import { SelectCrossPage } from './SelectCrossPage';
import {
  OptionLabelProps,
  SelectCrossPageProps,
  SelectCurrencyOptionContentProps,
  SelectCurrencyProps,
} from './interface-select';

export const OptionLabel = ({
  currency,
  children,
  color,
  ...restProps
}: BoxProps & OptionLabelProps) => (
  <Box display={'flex'} alignItems={'center'} color={color} padding={'8px 24px'} {...restProps}>
    <IconCurrency currency={currency} width={20} height={20} marginRight={'16px'} />
    {children}
  </Box>
);

export const SelectCurrencyOptionContent = ({
  currency,
  color,
  t,
  fontSize,
  isLongForm,
  gap,
}: SelectCurrencyOptionContentProps) => {
  const detailedCurrency = `${t(currency)} - `;
  return (
    <Box display={'flex'} alignItems={'center'} marginRight={'6px'} gap={gap}>
      <Box lineHeight={0} marginRight={'4px'}>
        <IconCurrency currency={currency} />
      </Box>
      <Box>
        <Typography
          fontSize={fontSize}
          lineHeight={'20px'}
          transition={'0.3s'}
          as={EActionAttribute.SPAN}
          color={color}
        >
          {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${isLongForm ? detailedCurrency : ''}${currency.toUpperCase()}`
          }
        </Typography>
      </Box>
    </Box>
  );
};

const SelectCurrencyContainer = ({
  color,
  fn,
  value,
  gap,
  shouldShowCurrencyLink,
  host,
  ...restProps
}: Partial<SelectCrossPageProps> & SelectCurrencyProps) => {
  const { currency: browserCurrency, changeCurrency } = useContextCurrency();
  const { fullUrl } = useFullUrl(host);
  const { t } = useTranslation(Translate.common.CURRENCY);

  const handleCurrency = ({ target: { value } }: SelectChangeEvent) => {
    const cur = getIsoCurrency(value, DEFAULT_CURRENCY);
    changeCurrency(cur);
  };

  return (
    <SelectCrossPage
      {...restProps}
      name={'selectCurrency'}
      value={value || browserCurrency}
      onChange={fn || handleCurrency}
      options={Object.values(ECurrency).map((currency) => {
        const href = shouldShowCurrencyLink && host ? getCurrencyLinkHref(fullUrl, currency) : null;

        return {
          value: currency,
          content: (
            <SelectCurrencyOptionContent
              currency={currency}
              color={color}
              t={t}
              gap={gap}
              fontSize={'13px'}
            />
          ),
          label: href ? (
            <NextLink legacyBehavior href={href} passHref>
              <Link>
                <OptionLabel currency={currency} color={color}>
                  {t(currency)} - {currency.toUpperCase()}
                </OptionLabel>
              </Link>
            </NextLink>
          ) : (
            <OptionLabel currency={currency} color={color}>
              {t(currency)} - {currency.toUpperCase()}
            </OptionLabel>
          ),
        };
      })}
    />
  );
};

export const SelectCurrency = memo(SelectCurrencyContainer);
