import { Accordion, Box, Typography } from '@core/component';
import {
  EAccordionClosingType,
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ESize,
  ETypographyVariant,
} from '@core/type';

import { AccordionProductDetailsProps } from './interface-tab-product';

export const AccordionProductDetails = ({ title, content }: AccordionProductDetailsProps) => {
  return (
    <Accordion
      titleContent={
        <Typography
          as={EActionAttribute.H2}
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
        >
          {title}
        </Typography>
      }
      titleActiveContent={
        <Typography
          variant={ETypographyVariant.H5}
          fontWeight={EFontWeight.MEDIUM}
          color={EColor.ACCENT}
        >
          {title}
        </Typography>
      }
      titlePadding={'20px'}
      borderRadius={'10px'}
      iconSize={ESize.SM}
      defaultIcon={EIcon.PLUS}
      activeIcon={EIcon.MINUS}
      iconActiveColor={EColor.ACCENT}
      backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
      closingType={EAccordionClosingType.FULL_CLICK}
    >
      <Box padding={'0 20px 20px'}>{content}</Box>
    </Accordion>
  );
};
