import useTranslation from 'next-translate/useTranslation';

import { Translate } from '@core/constant';
import { EButtonVariant } from '@core/type';

import { Button } from './Button';
import { ButtonBaseProps } from './interface-button';

export const ButtonClose = ({ ...restProps }: ButtonBaseProps): JSX.Element => {
  const { t } = useTranslation(Translate.common.BUTTON);

  return (
    <Button variant={EButtonVariant.OUTLINED} {...restProps}>
      {t('close.title')}
    </Button>
  );
};
