import useTranslation from 'next-translate/useTranslation';

import {
  Box,
  CardMedia,
  Icon,
  Image,
  LinkTranslated,
  LinkTranslatedStyledAsButton,
  SkeletonTypography,
  TextWithLineLimit,
  Typography,
} from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useQueryApi } from '@core/hook';
import { getStaticPathImage } from '@core/media';
import {
  EActionAttribute,
  EColor,
  EIcon,
  ERouting,
  ESize,
  ETypographyVariant,
  EZIndexName,
} from '@core/type';
import { BlogArticlesWidgetResponse, EQueryKey } from '@core/type/api';

import { WidgetBlogArticlesProps, ArticleProps } from './interface-blog-articles';

const Article = ({ article }: ArticleProps) => {
  const { slug, title, image, category } = article;

  return (
    <LinkTranslated
      keyRoute={ERouting.BLOG_ARTICLE}
      query={{
        category: category.slug,
        slug,
      }}
      isUnderlined={false}
      isUnderlinedHover={false}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" height={'48px'}>
        <Box display="flex" gap={'16px'} alignItems="center">
          <Box>
            {image ? (
              <CardMedia height={47} imageProps={image} width={77} />
            ) : (
              <Box height={'208px'} position={'relative'}>
                <Image
                  src={getStaticPathImage('card/article-default.png')}
                  alt={'de'}
                  priority
                  style={{
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
              </Box>
            )}
          </Box>

          {title && (
            <TextWithLineLimit as={EActionAttribute.H2}>
              <Typography
                variant={ETypographyVariant.H6}
                color={EColor.PRIMARY}
                textDecoration="none"
              >
                {title}
              </Typography>
            </TextWithLineLimit>
          )}
        </Box>

        <Box display="flex" justifyContent="flex-end" marginLeft={'32px'}>
          <Box>
            <Icon
              type={EIcon.ARROW_DOWN}
              color={EColor.PRIMARY}
              size={ESize.SM}
              transform="rotate(0.75turn)"
            ></Icon>
          </Box>
        </Box>
      </Box>
    </LinkTranslated>
  );
};

export const WidgetBlogArticles = ({ slug }: WidgetBlogArticlesProps) => {
  const { isPhone } = useContextMediaQuery();
  const { t } = useTranslation(Translate.common.WIDGET);

  const {
    data: articles,
    isLoading,
    isError,
  } = useQueryApi<BlogArticlesWidgetResponse, WidgetBlogArticlesProps>(
    EQueryKey.WIDGET_BLOG_ARTICLES,
    {
      slug,
    },
  );

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box
        marginTop={isPhone ? '120px' : '160px'}
        position={'relative'}
        zIndex={EZIndexName.OVER_DEFAULT}
        maxWidth={'750px'}
      >
        <Box>
          <Typography variant={ETypographyVariant.H2} marginBottom={'30px'}>
            {t('home.articlesTitle')}
          </Typography>
        </Box>

        {articles?.blogArticles?.length > 0 && (
          <Box display="flex" gap="16px" flexDirection="column">
            {articles?.blogArticles.map((el) => <Article article={el} key={el.slug} />)}
          </Box>
        )}
        <Box marginTop="48px" display={'flex'} justifyContent={'center'}>
          <Box width={isPhone ? '100%' : '328px'}>
            <LinkTranslatedStyledAsButton keyRoute={ERouting.BLOG_HOME} width={'100%'}>
              {isPhone ? t('blogArticles.readMore') : t('home.discoverOurBlog')}
            </LinkTranslatedStyledAsButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
