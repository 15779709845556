import { TrustpilotLong, TrustpilotNextScript, getTrustpilotTag } from '@trustpilot';
import { useEffect, useState } from 'react';

import { Box } from '@core/component';
import {
  TRUSTPILOT_API_URL,
  TRUSTPILOT_STARS,
  TRUSTPILOT_TAG,
  TRUSTPILOT_WIDGET_URL,
  trustpilotIds,
} from '@core/constant';
import { useContextUtil } from '@core/context';
import { Logger } from '@core/logger';
import { ELanguage, ELanguageTags, ERouting, EZIndexName } from '@core/type';
import { SanityWidget } from '@core/type/sanity';
import { addParameters, getLanguageFromTags, getLanguageTags } from '@core/util';

import { getEnvironmentPublic } from '../../../helpers';

export type HomepageTrustpilotProps = { language: ELanguageTags; tag: string };
export type HomepageTrustpilotSanityResponse = {
  language: ELanguageTags;
  tag: string;
  apiParams: { language: ELanguageTags };
};

export const HomepageTrustpilot = ({ language, tag }: HomepageTrustpilotProps) => {
  const { nonce, isBotDetected } = useContextUtil();
  const [shouldShowWidget, setShouldShowWidget] = useState<boolean>(false);

  useEffect(() => {
    if (language && tag) {
      fetch(
        addParameters(
          `${TRUSTPILOT_API_URL}/v1/business-units/${trustpilotIds.BUSINESSUNIT_ID}/reviews`,
          {
            apikey: getEnvironmentPublic().trustpilotApiKey,
            tagValue: getTrustpilotTag(language, tag),
            perPage: '1',
            stars: TRUSTPILOT_STARS,
            language: getLanguageFromTags(language),
          },
        ),
      )
        .then((response) => response.json())
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          setShouldShowWidget(response?.reviews?.length);
        })
        .catch((error) => {
          Logger.logError(error, { isException: true, route: ERouting.HOME, language });
        });
    }
  }, [tag, language]);

  return (
    <>
      {getEnvironmentPublic().enableTrustpilot && !isBotDetected && (
        <TrustpilotNextScript url={TRUSTPILOT_WIDGET_URL} nonce={nonce} />
      )}

      {!!shouldShowWidget && (
        <Box position={'relative'} zIndex={EZIndexName.OVER_DEFAULT}>
          <TrustpilotLong locale={language} tag={tag} />
        </Box>
      )}
    </>
  );
};

export const getHomepageTrustpilot = (section: SanityWidget): JSX.Element => {
  const params = section as unknown as HomepageTrustpilotSanityResponse;
  return (
    <HomepageTrustpilot
      language={
        (getLanguageTags(params.language as unknown as ELanguage) as ELanguageTags) ??
        (params.apiParams?.language as ELanguageTags)
      }
      tag={params?.tag ?? TRUSTPILOT_TAG}
    />
  );
};
