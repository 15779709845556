import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Box, Chart, Icon, Typography } from '@core/component';
import { Translate } from '@core/constant';
import {
  useContextCurrency,
  useContextLiveChartsPrice,
  useContextMediaQuery,
  useContextMetalPrice,
} from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import { getStaticPathImage } from '@core/media';
import { useMetalIso, useWeightUnit } from '@core/provider';
import {
  EActionAttribute,
  EColor,
  ECurrency,
  EFontWeight,
  EIcon,
  ELiveChartsPeriod,
  EMetal,
  ESize,
  ETypographyVariant,
  EUnit,
  EZIndexName,
  ThemeProps,
  WeightUnitType,
} from '@core/type';
import { EQueryKey, LiveChartsRequest, LiveChartsResponse } from '@core/type/api';
import { ChartChoices } from '@core/util';

import { ButtonSetMarketAlert } from './ButtonSetMarketAlert';
import { Chip } from './ChipPeriod';
import { LivePrice } from './LivePrice';
import { ModalPrice } from './ModalPrice';
import { PriceChange } from './PriceChange';
import { SelectChartCategories } from './SelectChartCategories';
import { ESelectChartCategory, WidgetChartProps } from './interface-charts';
import { useCharts } from './use-charts-translation';
import { chips, chipsMobile } from './utils-translation';

const getTimePeriod = (period: ELiveChartsPeriod) => {
  if (period === ELiveChartsPeriod.DAY) {
    return EUnit.HOUR;
  }
  if (period === ELiveChartsPeriod.WEEK || period === ELiveChartsPeriod.MONTH) {
    return EUnit.DAY;
  }
  return EUnit.MONTH;
};

export const WidgetChart = ({
  currency: defaultCurrencyProps,
  metal,
  period: defaultPeriod = ELiveChartsPeriod.DAY,
  weightUnit: defaultWeightUnit,
  shouldChangeUrl,
  title,
  subtitle,
}: Omit<ChartChoices, 'language' | 'currency'> & WidgetChartProps) => {
  const {
    structure: {
      charts: {
        headerBox: { padding: headerBoxPadding, margin: headerBoxMargin },
        chartPaper: { maxWidth: headerBoxMaxWidth, padding: chartPaperPadding },
      },
    },
  } = useTheme() as ThemeProps;

  const { currency: contextCurrency, changeCurrency } = useContextCurrency();
  const { language } = useLanguage();
  const { t } = useTranslation(Translate.common.WIDGET);

  const defaultCurrency = Object.keys(ECurrency).includes(defaultCurrencyProps)
    ? defaultCurrencyProps
    : contextCurrency;
  const [period, setPeriod] = useState(defaultPeriod);
  const [currency, setCurrency] = useState<ECurrency>(defaultCurrency);

  const { setMetalIso } = useMetalIso();
  const { weightUnit, setWeightUnit } = useWeightUnit();

  useEffect(() => {
    setMetalIso(metal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metal]);

  useEffect(() => {
    if (defaultWeightUnit) {
      setWeightUnit(defaultWeightUnit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultWeightUnit]);

  useEffect(() => {
    changeCurrency(currency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  useEffect(() => {
    setCurrency(contextCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextCurrency]);

  const {
    categoryTitleDowncase,
    categoryTitle,
    periodTranslation,
    firstPrefix,
    secondPrefix,
    weightTranslation,
    optionsForWeightUnit,
    optionsForMetal,
  } = useCharts({ metal, period, weightUnit, language });

  const { t: tButton } = useTranslation(Translate.common.BUTTON);

  const { isPhone } = useContextMediaQuery();

  const { data: charts } = useQueryApi<LiveChartsResponse, LiveChartsRequest>(
    EQueryKey.LIVE_CHARTS,
    {
      metal,
      weightUnit,
      currency: currency || defaultCurrencyProps,
      period,
    },
  );

  const websocketsPrices = useContextLiveChartsPrice();

  const rawPriceData =
    period === ELiveChartsPeriod.DAY && websocketsPrices?.liveChartsPrices?.pricesList?.length > 0
      ? websocketsPrices.liveChartsPrices.pricesList
      : charts?.pricesList;

  const priceData = rawPriceData?.map(({ date, price, priceOunce }) => ({
    x: date,
    y: price,
    tooltipData: {
      priceOunce,
    },
  }));

  const priceChanges =
    period === ELiveChartsPeriod.DAY
      ? websocketsPrices?.liveChartsPrices?.priceChanges
      : charts?.priceChanges;

  const [shouldShowMoreInfoModal, setShouldShowMoreInfoModal] = useState(false);

  const { prices: metalPrices } = useContextMetalPrice();

  const metalLabel = optionsForMetal.find(({ value }) => (value as EMetal) === metal).content;
  const weightUnitLabel = optionsForWeightUnit.find(
    ({ value }) => (value as EUnit) === weightUnit,
  ).content;

  const hiddenH2Text = [' ', metalLabel, weightUnitLabel].join(' ');

  const subtitleLines = subtitle?.split('\n');

  return (
    <>
      <Box
        backgroundImage={
          isPhone ? undefined : `url(${getStaticPathImage('charts/title-pattern.png')})`
        }
        backgroundSize="cover"
        backgroundPosition="bottom"
        backgroundRepeat="no-repeat"
        backgroundColor={EColor.INFO}
        padding={headerBoxPadding}
        margin={headerBoxMargin}
        borderRadius={isPhone ? undefined : '8px'}
      >
        <Typography variant={ETypographyVariant.H2} marginBottom="16px" as={EActionAttribute.H1}>
          {title ||
            t('chart.header', {
              categoryTitleDowncase,
              category: categoryTitle,
              firstPrefix,
              secondPrefix,
              weightTranslation,
              currency: currency || defaultCurrencyProps,
              period: periodTranslation,
            })}
        </Typography>
        {subtitleLines ? (
          subtitleLines.map((line, index) => (
            <Typography color={EColor.SECONDARY} key={index}>
              {line}
            </Typography>
          ))
        ) : (
          <Typography color={EColor.SECONDARY}>
            <Trans
              i18nKey={`${Translate.common.WIDGET}:chart.headerHtml`}
              values={{
                categoryTitleDowncase,
                categoryTitle,
                firstPrefix,
                secondPrefix,
              }}
              components={{ br: <br /> }}
            />
          </Typography>
        )}
      </Box>

      <Box
        backgroundColor={{ semanticType: EColor.NEUTRAL, variant: EColor.LIGHT }}
        margin="0 auto"
        boxShadow={isPhone ? undefined : EColor.ELEVATION_3}
        padding={chartPaperPadding}
        borderRadius={isPhone ? undefined : '8px'}
        maxWidth={headerBoxMaxWidth}
      >
        <Box marginBottom="30px">
          <Box display={'flex'} gap={'16px'} flexWrap={'wrap'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box marginRight="10px">
                <Icon type={EIcon.LIVE_PRICE} width="24px" height="24px" />
              </Box>
              <Typography
                variant={ETypographyVariant.BODY1}
                as={EActionAttribute.H2}
                fontWeight={EFontWeight.MEDIUM}
                flexShrink={'0'}
                display={'inline-block'}
                whiteSpace={'nowrap'}
              >
                {t('chart.livePrice')}
                <Typography display="none">{hiddenH2Text}</Typography>
              </Typography>
            </Box>
            <Box flexGrow={1} display={'flex'} gap={'16px'} flexWrap={'wrap'}>
              <Box minWidth={'132px'}>
                <SelectChartCategories
                  value={metal}
                  options={optionsForMetal}
                  onChange={({ target: { value } }) => {
                    setMetalIso(value as EMetal);
                  }}
                  type={ESelectChartCategory.METAL}
                  linkProps={shouldChangeUrl ? { currency, language } : undefined}
                />
              </Box>
              <Box minWidth={'142px'}>
                <SelectChartCategories
                  value={weightUnit}
                  options={optionsForWeightUnit}
                  onChange={({ target: { value } }) => setWeightUnit(value as WeightUnitType)}
                  type={ESelectChartCategory.WEIGHT_UNIT}
                />
              </Box>
              <Box minWidth={'110px'}>
                <SelectChartCategories
                  value={currency || defaultCurrencyProps}
                  options={(Object.keys(ECurrency) as (keyof typeof ECurrency)[]).map((key) => ({
                    value: ECurrency[key],
                    content: key,
                    label: key,
                  }))}
                  onChange={({ target: { value } }) => setCurrency(value as ECurrency)}
                  type={ESelectChartCategory.CURRENCY}
                  linkProps={shouldChangeUrl ? { metal, language } : undefined}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={'24px'}
          flexWrap={'wrap'}
        >
          <Box>
            <LivePrice
              metalPrice={priceChanges?.metalPrice}
              {...charts?.spotPrice}
              currency={currency}
            />

            <Box
              display="inline-block"
              verticalAlign="top"
              onClick={() => {
                setShouldShowMoreInfoModal(true);
              }}
              cursor="pointer"
            >
              <Icon
                type={EIcon.INFO}
                display="inline-block"
                marginLeft="20px"
                size={ESize.XXS}
                color={EColor.SECONDARY}
              />
              <Typography
                variant={ETypographyVariant.CAPTION2}
                marginLeft="5px"
                color={EColor.SECONDARY}
                display="inline-block"
              >
                {tButton('moreInfo.title')}
              </Typography>
            </Box>
          </Box>
          {!isPhone && (
            <Box maxWidth={'350px'} flexGrow={1}>
              <ButtonSetMarketAlert metal={metal} />
            </Box>
          )}
        </Box>

        {priceChanges?.difference ? (
          <Box marginTop={'10px'} marginBottom={'24px'} display={'flex'} flexWrap={'wrap'}>
            <Typography display="inline-block" marginRight="10px">
              {t('chart.priceVariation', {
                categoryTitle,
                categoryTitleDowncase,
                period: periodTranslation,
                firstPrefix,
              })}
            </Typography>

            <PriceChange
              priceChange={priceChanges.difference}
              isPositive={priceChanges.difference > 0}
              percentage={priceChanges.percentage}
            />
          </Box>
        ) : (
          <Box height={isPhone ? '56px' : '58px'} />
        )}
        <Box position={'relative'} zIndex={EZIndexName.OVER_DEFAULT}>
          {!isPhone &&
            chips.map(({ text, period: periodChips }) => (
              <Chip
                margin={'8px'}
                isActive={periodChips === period}
                key={periodChips}
                onClick={() => setPeriod(periodChips)}
              >
                {t(text)}
              </Chip>
            ))}
        </Box>
        {priceData ? (
          <Chart
            metalIso={metal}
            dataSeries={priceData}
            language={language}
            currency={currency}
            timeUnit={getTimePeriod(period)}
          />
        ) : (
          <Box height={'500px'} />
        )}
        {isPhone && (
          <>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              margin={'16px 0 32px'}
              position={'relative'}
              zIndex={EZIndexName.OVER_DEFAULT}
            >
              {chipsMobile.map(({ text, period: periodChips }) => (
                <Chip
                  isActive={periodChips === period}
                  key={periodChips}
                  onClick={() => setPeriod(periodChips)}
                >
                  {t(text)}
                </Chip>
              ))}
            </Box>
            <Box marginTop={'32px'}>
              <ButtonSetMarketAlert metal={metal} />
            </Box>
          </>
        )}
      </Box>

      <ModalPrice
        isOpen={shouldShowMoreInfoModal}
        onCloseModal={() => {
          setShouldShowMoreInfoModal(false);
        }}
        categoryTitleDowncase={categoryTitleDowncase}
        {...metalPrices[metal]}
      />
    </>
  );
};
