import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import {
  Box,
  Grid,
  LinkTranslatedStyledAsLink,
  SkeletonTypography,
  Typography,
} from '@core/component';
import { Translate, metalToSlug } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { useLanguage, useQueryApi } from '@core/hook';
import {
  EActionAttribute,
  EColor,
  EFontWeight,
  EIcon,
  ELinkAnimation,
  EMetal,
  EPosition,
  ERouting,
  ETypographyVariant,
  EZIndexName,
} from '@core/type';
import { EQueryKey, HomepageProductsCategoryEntity } from '@core/type/api';

import { HomepageSelect } from './HomepageSelect';
import { ProductsPaperBox } from './ProductsPaperBox';

const ITEMS_TO_DISPLAY = 4;

export const ProductsBlock = ({ metalIso }: { metalIso: EMetal.GOLD | EMetal.SILVER }) => {
  const { isPhone, isDesktop } = useContextMediaQuery();
  const { language } = useLanguage();

  const { t: tWidget } = useTranslation(Translate.common.WIDGET);

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const [selectMetal, setSelectMetal] = useState<EMetal.GOLD | EMetal.SILVER>(metalIso);

  const {
    data: products,
    isLoading,
    isError,
  } = useQueryApi<HomepageProductsCategoryEntity, { metalIso: EMetal.GOLD | EMetal.SILVER }>(
    EQueryKey.COMPONENT_PRODUCTS,
    { metalIso: selectMetal },
  );

  const LinkToGoldPage = () => (
    <LinkTranslatedStyledAsLink
      keyRoute={ERouting.BUY}
      query={{ metal: metalToSlug[language][selectMetal] }}
      color={EColor.ACCENT}
      endIcon={EIcon.ARROW_DOWN}
      iconPosition={EPosition.RIGHT}
      iconColor={EColor.SECONDARY}
      animation={ELinkAnimation.ARROW_RIGHT}
      iconOrientation={EPosition.RIGHT}
      isIconStroke
    >
      {tWidget('home.discoverProducts')}
    </LinkTranslatedStyledAsLink>
  );

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <Box marginBottom={'48px'}>
        <SkeletonTypography lineHeight={'68px'} marginBottom={'8px'} />
        <SkeletonTypography lineHeight={'68px'} />
      </Box>
    );
  }

  return (
    <Box
      marginTop={isPhone ? '120px' : '160px'}
      position={'relative'}
      zIndex={EZIndexName.OVER_DEFAULT}
    >
      <Box
        display={'flex'}
        gap={'16px'}
        alignItems={isPhone ? 'flex-start' : 'center'}
        marginBottom={'24px'}
        flexDirection={isPhone ? 'column' : 'row'}
      >
        <Typography as={EActionAttribute.H2} variant={ETypographyVariant.DISPLAY}>
          {tWidget('home.timeToBuy')}
        </Typography>

        <HomepageSelect selectMetal={selectMetal} setSelectMetal={setSelectMetal} />
      </Box>

      <Box display={'flex'} alignItems={'flex-end'} justifyContent={'space-between'}>
        <Typography
          variant={ETypographyVariant.H4}
          fontWeight={EFontWeight.REGULAR}
          maxWidth={'750px'}
        >
          {tWidget('home.buyDescription')}
        </Typography>

        {isDesktop && (
          <Box marginLeft={'16px'} minWidth={'max-content'}>
            <LinkToGoldPage />
          </Box>
        )}
      </Box>

      {products?.products?.length >= ITEMS_TO_DISPLAY && (
        <Box marginTop={'62px'}>
          <Grid isContainer spacing={30}>
            <Grid lg={8} md={6} sm={12}>
              <ProductsPaperBox product={products?.products[0]} metal={selectMetal} isLargeBox />
            </Grid>

            <Grid lg={4} md={6} sm={12}>
              <ProductsPaperBox product={products?.products[1]} metal={selectMetal} />
            </Grid>

            <Grid lg={8} md={6} sm={12}>
              <ProductsPaperBox
                product={products?.products[2]}
                metal={selectMetal}
                isLargeBox
                isReversed
              />
            </Grid>

            <Grid lg={4} md={6} sm={12}>
              <ProductsPaperBox product={products?.products[3]} metal={selectMetal} />
            </Grid>
          </Grid>
        </Box>
      )}

      {!isDesktop && (
        <Box textAlign={'right'} marginTop={'56px'}>
          <LinkToGoldPage />
        </Box>
      )}
    </Box>
  );
};
