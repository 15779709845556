import { useContextGR } from '@google-recaptcha';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, FormEventHandler, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { WidgetService } from '@core/api';
import { Box, Icon, InputHidden, Typography } from '@core/component';
import { InputTextProps } from '@core/component/interface';
import { Translate } from '@core/constant';
import { useContextUtil } from '@core/context';
import { useLanguage } from '@core/hook';
import { Logger } from '@core/logger';
import { EColor, EIcon, ESize, ETypographyVariant } from '@core/type';
import { SanityWidget } from '@core/type/sanity';

import { WidgetRatingStarsComponentProps, WidgetRatingStarsProps } from './interface-rating-stars';

const TRANSITION_DELAY = 400;

export const WidgetRatingStars = ({ text, pageId }: WidgetRatingStarsProps) => {
  const { axiosInstance } = useContextUtil();
  const { language } = useLanguage();
  const { getRecaptcha } = useContextGR();
  const { t } = useTranslation(Translate.common.WIDGET);

  const [isVisible, setIsVisible] = useState(false);
  const [isPageRated, setIsPageRated] = useState(true);

  useEffect(() => {
    if (!WidgetService.getRatedPageState(pageId)) {
      setIsVisible(true);
      setIsPageRated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPageRated) {
      if (isVisible) {
        setTimeout(() => {
          setIsVisible(false);
        }, TRANSITION_DELAY);
      }
    } else {
      setIsVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageRated]);

  const handleRating = async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    try {
      await WidgetService.patchPageRating(axiosInstance, language, {
        pageId,
        rating: value,
        recaptchaToken: await getRecaptcha(),
      });
      WidgetService.setRatedPageState(pageId);
      setIsPageRated(true);
    } catch (e) {
      Logger.logError(e, { isException: true });
    }
  };

  return (
    <Box
      margin={'0px auto'}
      maxWidth={'fit-content'}
      backgroundColor={{ semanticType: EColor.SECONDARY, variant: EColor.LIGHT }}
      padding={'28px'}
      textAlign={'center'}
      display={isVisible ? 'block' : 'none'}
      borderRadius={'16px'}
      opacity={isPageRated ? 0 : 1}
      transition={'opacity 0.3s'}
    >
      <Typography variant={ETypographyVariant.H5} marginBottom={'16px'}>
        {text || t('ratingStars.defaultTitle')}
      </Typography>
      <StyledFieldset onChange={handleRating as unknown as FormEventHandler<HTMLFieldSetElement>}>
        <FieldRatingStar id={'starRating5'} value={'5'} />
        <FieldRatingStar id={'starRating4'} value={'4'} />
        <FieldRatingStar id={'starRating3'} value={'3'} />
        <FieldRatingStar id={'starRating2'} value={'2'} />
        <FieldRatingStar id={'starRating1'} value={'1'} />
      </StyledFieldset>
    </Box>
  );
};

const IconYellowStar = () => (
  <Icon
    type={EIcon.STAR}
    size={ESize.MD}
    secondColor={{ commonType: EColor.YELLOW, intensity: EColor.R100 }}
  />
);

const FieldRatingStar = ({ id, value }: Pick<InputTextProps, 'id' | 'value'>) => (
  <>
    <InputHidden name={'rating-stars'} value={value} type={'radio'} id={id} />
    <StyledLabel htmlFor={id}>
      <StyledStarWrapper>
        <IconYellowStar />
      </StyledStarWrapper>
      <StyledScaleStarWrapper>
        <IconYellowStar />
      </StyledScaleStarWrapper>
    </StyledLabel>
  </>
);

export const getRatingStarsComponent = (section: SanityWidget, pageId: string): JSX.Element => (
  <WidgetRatingStars pageId={pageId} text={(section as WidgetRatingStarsComponentProps).text} />
);

export const getStarPulseAnimation = () => keyframes`
  10% {
    transform: scale(1);
    opacity: 1;
  }

  90% {
    transform: scale(2.5);
    opacity: 0;
  }
`;

export const getStarShakeAnimation = () => keyframes`
  10%,
  90% {
    transform: rotateZ(-1deg);
  }

  20%,
  80% {
    transform: rotateZ(2deg);
  }

  30%,
  50%,
  70% {
    transform: rotateZ(-3deg);
  }

  40%,
  60% {
    transform: rotateZ(3deg);
  }
`;

const StyledLabel = styled.label`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
`;

const StyledStarWrapper = styled.div`
  height: 24px;
  width: 24px;
`;

const StyledScaleStarWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  opacity: 0;
`;

// stylelint-disable no-descending-specificity, max-line-length
const StyledFieldset = styled.fieldset`
  display: flex;
  gap: 10px;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 0;
  border: none;
  position: relative;

  &:focus-within {
    outline: none;
  }

  ${StyledStarWrapper} svg path:first-of-type {
    transition: 0.3s;
    transition-delay: 0s;
    opacity: 0;
  }

  input:checked ~ ${StyledLabel} ${StyledStarWrapper} svg path:first-of-type,
  ${StyledLabel}:hover ${StyledStarWrapper} svg path:first-of-type,
  ${StyledLabel}:hover ~ input ~ ${StyledLabel} ${StyledStarWrapper} svg path:first-of-type {
    opacity: 1;
  }

  input:checked + ${StyledLabel} > ${StyledStarWrapper} {
    animation: ${getStarShakeAnimation()} 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: rotateZ(0);
  }

  input:checked + ${StyledLabel} > ${StyledScaleStarWrapper} {
    animation: ${getStarPulseAnimation()} 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) forwards;
  }

  @media (prefers-reduced-motion) {
    input:checked + ${StyledLabel} > ${StyledStarWrapper} {
      animation: initial;
    }

    input:checked + ${StyledLabel} > ${StyledScaleStarWrapper} {
      display: none;
    }
  }
`;
